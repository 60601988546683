import { homepageConstants } from "../constant";

const initialState = {
  homepage: {
    gameName: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case homepageConstants.HOMEPAGE_STOREHOMEPAGE:
      return { ...state, homepage: action.payload };

    default:
      return state;
  }
};
