import { combineReducers } from "redux";
import localeReducer from "./modules/locale/reducer";
import forumReducer from "./modules/forum/reducer";
import copywritingReducer from "./modules/copywriting/reducer";
import homepageReducer from "./modules/homepage/reducer";
import siteSettingReducer from "./modules/site-setting/reducer";

const rootReducer = combineReducers({
  localeReducer,
  copywritingReducer,
  forumReducer,
  homepageReducer,
  siteSettingReducer,
});

export default rootReducer;
