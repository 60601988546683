import { createStore } from "redux";
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

// preloadedState will be passed in by the plugin
export default preloadedState => {
  // on server
  if (typeof window == `undefined`) {
    return createStore(rootReducer, preloadedState);
  }

  // on client
  const persistedState = window.localStorage.getItem("reduxState")
    ? JSON.parse(window.localStorage.getItem("reduxState"))
    : {};

  const loadDevTools = () =>
    process.env.NODE_ENV === "development" && window.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f;

  const store = createStore(
    rootReducer,
    {
      ...preloadedState,
      ...persistedState,
    },
    compose(applyMiddleware(thunk), loadDevTools())
    /* any middleware... */
  );

  store.subscribe(() => {
    window.localStorage.setItem("reduxState", JSON.stringify(store.getState()));
  });

  return store;
};
