import { copywritingActionConstants } from "../constant";

const initialState = {
  copywriting: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case copywritingActionConstants.COPYWRITING_STORE:
      return { ...state, copywriting: action.payload };
    default:
      return state;
  }
};
