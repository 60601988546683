// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-modules-game-intro-pages-index-js": () => import("./../../../src/modules/game-intro/pages/index.js" /* webpackChunkName: "component---src-modules-game-intro-pages-index-js" */),
  "component---src-modules-news-pages-details-news-details-controller-js": () => import("./../../../src/modules/news/pages/details/news-details-controller.js" /* webpackChunkName: "component---src-modules-news-pages-details-news-details-controller-js" */),
  "component---src-modules-news-pages-list-news-list-controller-js": () => import("./../../../src/modules/news/pages/list/news-list-controller.js" /* webpackChunkName: "component---src-modules-news-pages-list-news-list-controller-js" */),
  "component---src-modules-shared-components-2-0-block-container-list-block-container-list-controller-js": () => import("./../../../src/modules/shared-components/2.0/block-container-list/block-container-list-controller.js" /* webpackChunkName: "component---src-modules-shared-components-2-0-block-container-list-block-container-list-controller-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/_404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/_index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

