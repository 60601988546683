import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { changeLocale } from "../../src/modules/locale/action";

/* Parse QueryString using String Splitting */
const parseQueryStringToDictionary = queryString => {
  var dictionary = {};

  // remove the '?' from the beginning of the
  // if it exists
  if (queryString.indexOf("?") === 0) {
    queryString = queryString.substr(1);
  }

  // Step 1: separate out each key/value pair
  var parts = queryString.split("&"); //&amp;

  for (var i = 0; i < parts.length; i++) {
    var p = parts[i];
    // Step 2: Split Key/Value pair
    var keyValuePair = p.split("=");

    // Step 3: Add Key/Value pair to Dictionary object
    var key = keyValuePair[0];
    var value = keyValuePair[1];

    // decode URI encoded string
    value = decodeURIComponent(value);
    value = value.replace(/\+/g, " ");

    dictionary[key] = value;
  }

  // Step 4: Return Dictionary Object
  return dictionary;
};

const parsePathUrl = pathUrl => {
  var langVal = pathUrl.pathname.split("/");
  //use index 1 because the index-0 is an empty string from the split above
  return langVal[1];
};

export default function PathRouteRedux(props) {
  const { location } = props;

  const locales = useSelector(state => {
    return state.localeReducer.locales;
  }, []);

  //Using query params
  // const params = parseQueryStringToDictionary(location.search);

  //Using path url
  const localeVal = parsePathUrl(location);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    //Using query params
    // var selectedLocale = locales.find(lo => lo.display_code == params["lang"]);
    var selectedLocale = locales
      ? locales.find(lo => lo.display_code == localeVal)
      : locales.find(lo => lo.default == true)
      ? locales.find(lo => lo.default == true)
      : locales[0];

    if (selectedLocale) {
      stableDispatch(changeLocale(selectedLocale.code));
    }
  }, [location]);

  return <React.Fragment>{props.children}</React.Fragment>;
}

PathRouteRedux.propTypes = {
  children: PropTypes.node,
};
