import { localeActionConstants } from "../constant";

const initialState = {
  locale: "en-US",
  locales: [
    {
      code: "en-US",
      default: true,
      display_code: "en",
      name: "English",
      native_name: "English (United States)",
      presentation_name: "English",
      rtl: false,
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case localeActionConstants.LOCALE_CHANGE:
      return { ...state, locale: action.payload };

    case localeActionConstants.LOCALE_STORE:
      return { ...state, locales: action.payload };
    default:
      return state;
  }
};
