import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { storeSiteSettingValue } from "../../src/modules/site-setting/action";
import { storeLocales } from "../../src/modules/locale/action";

export default function PreStore(props) {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    if (props.pageContext.siteSetting) {
      stableDispatch(storeSiteSettingValue(props.pageContext.siteSetting));
    }

    if (props.pageContext.locales) {
      stableDispatch(storeLocales(props.pageContext.locales));
    }
  }, [props.pageContext, stableDispatch]);

  return <React.Fragment>{props.children}</React.Fragment>;
}

PreStore.propTypes = {
  children: PropTypes.node,
};
