/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/global-styles.css";

// For fixing safari browser lazy loading issue
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

// // trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateFound = () => {
  console.log("onServiceWorkerUpdateFound");
  // DO ALL
  // history.go(0);
  // solution 5
  window.location.href = window.location.href;

  // solution 2
  window.location.reload();
  // solution 1
  setTimeout(window.location.reload);

  // solution 4
  window.location.reload(true);
  // solution 3
  setTimeout(window.location.reload(true));

  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // );

  // if (answer === true) {
  //   // DO ALL
  //   // solution 1
  //   setTimeout(window.location.reload);
  //   // solution 2
  //   window.location.reload();
  //   // solution 3
  //   setTimeout(window.location.reload(true));

  //   window.location.reload(true);
  //   // solution 4
  //   // history.go(0);
  //   // solution 5
  //   window.location.href = window.location.href;
  // }
};

// // trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => {
  console.log("onServiceWorkerUpdateReady");
  // DO ALL

  // history.go(0);
  // solution 5
  window.location.href = window.location.href;

  // solution 2
  window.location.reload();
  // solution 1
  setTimeout(window.location.reload);

  // solution 4
  window.location.reload(true);
  // solution 3
  setTimeout(window.location.reload(true));

  // const answer = window.confirm(
  //   `This application has been updated. ` +
  //     `Reload to display the latest version?`
  // );
  // if (answer === true) {
  //   // DO ALL
  //   // solution 1
  //   setTimeout(window.location.reload);
  //   // solution 2
  //   window.location.reload();
  //   // solution 3
  //   setTimeout(window.location.reload(true));

  //   window.location.reload(true);
  //   // solution 4
  //   // history.go(0);
  //   // solution 5
  //   window.location.href = window.location.href;
  // }
};
