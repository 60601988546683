import { forumActionConstants } from "../constant";
import _ from "lodash";

const initialState = {
  hasInit: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case forumActionConstants.FORUM_STORE_SECRET:
      return { ...state, ...action.payload };
    default:
      if (_.isEmpty(state)) {
        return initialState;
      } else {
        return state;
      }
  }
};
