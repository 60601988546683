import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const primaryColor = "#556cd6";
const secondaryColor = "#F1872C";
const whiteColor = "#fff";
const blackColor = "#fff";
const greyColor = "#333";

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecorationLine: "none",
          color: greyColor,
        },
        "@media (hover: hover)": {
          "a:hover, .text-hover:hover": {
            color: secondaryColor + "!important",
          },
        },
        ".text-active": {
          color: secondaryColor + "!important",
        },
        ".accordion-category-title": {
          backgroundColor: secondaryColor,
        },
        ".gallery .flickity-button": {
          backgroundColor: secondaryColor,
        },

        ".accordion-expand-hide-title.Mui-expanded .MuiAccordionSummary-expandIcon svg": {
          backgroundColor: secondaryColor,
        },
        ".text-secondary": {
          color: secondaryColor,
        },
        ".text-shadow": {
          textShadow: "2px 2px 2px rgba(47,47,47,0.8)",
        },
        ".media-wrapper.MuiPaper-rounded": {
          borderRadius: "20px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: whiteColor,
    },
  },
  typography: {
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
      lineHeight: "1.25",
    },
    body2: {
      lineHeight: "1",
    },
  },
});

export default theme;
